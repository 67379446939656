import api from '@/libs/axios'

export default {
  async create(payload) {
    let response = await api.post('/admin/info-companies', payload)
    return response
  },
  async getAll() {
    let response = await api.get('/admin/info-companies');
    return response
  },
  async update(payload) {
    let response = await api.put('/admin/info-companies', payload)
    return response
  },
  async delete(payload) {
    const response = await api.delete('/admin/info-companies', {
      data: payload,
    })
    return response
  },
}
