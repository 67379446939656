<template>
  <b-modal
    id="tab"
    ok-only
    ok-title="Save"
    centered
    title="Create"
    v-model="isOpen"
    @ok="save"
  >
    <b-form-group label="Trang web" label-for="h-web" label-cols-md="4">
      <b-form-input
        id="h-wed"
        type="text"
        v-model="infoCompany.web"
        placeholder="Trang web"
      />
    </b-form-group>
    <b-form-group label="Hòm thư hỗ trợ" label-for="h-email" label-cols-md="4">
      <b-form-input
        id="h-email"
        type="text"
        v-model="infoCompany.email"
        placeholder="Hòm thư hỗ trợ"
      />
    </b-form-group>
    <b-form-group label="Hotline" label-for="h-hotline" label-cols-md="4">
      <b-form-input
        id="h-hotline"
        type="text"
        v-model="infoCompany.hotline"
        placeholder="Hotline"
      />
    </b-form-group>
    <b-form-group label="Language" label-for="h-language" label-cols-md="4">
      <language-select
        :language="infoCompany.language ? infoCompany.language : null"
        @selectedLanguage="selectedLanguage"
      />
    </b-form-group>
    <b-form-group label="Community" label-for="h-community" label-cols-md="4">
      <b-form-input
        id="h-community"
        type="text"
        v-model="infoCompany.community"
        placeholder="Community"
      />
    </b-form-group>
    <b-form-group label="Terms of Use" label-for="h-term" label-cols-md="4">
      <b-form-input
        id="h-term"
        type="text"
        v-model="infoCompany.terms_of_use"
        placeholder="term"
      />
    </b-form-group>
    <b-form-group label="Privacy Policty" label-for="h-privacy" label-cols-md="4">
      <b-form-input
        id="h-privacy"
        type="text"
        v-model="infoCompany.privacy_policty"
        placeholder="privacy"
      />
    </b-form-group>
  </b-modal>
</template>
<script>
export default {
  data() {
    return {
      isOpen: false,
      isUpdate: false,
      infoCompany: {},
    };
  },
  methods: {
    openModal(info) {
      if (info && info.language) {
        this.infoCompany = info;
        this.isUpdate = true;
      }
      this.isOpen = true;
    },
    save() {
      if (this.isUpdate) {
        this.$emit("update", this.infoCompany);
        this.isUpdate = false;
      } else {
        this.$emit("create", this.infoCompany);
      }
      this.infoCompany = {};
    },
    selectedLanguage(language) {
      this.infoCompany.language = language;
    },
  },
};
</script>
